<template>
  <div>
    <v-app>
      <div class="card">
        <!--begin::Details-->
          <div class="d-flex mb-9">
            <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3"></div>
            <!--begin::Info-->
            <div class="flex-grow-1">
              <!--begin::Title-->
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex mr-3">
                  <a href="#" class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold  mr-3">
                    {{candidate_summary.first_name ? candidate_summary.first_name : "" }}
                    {{ candidate_summary.middle_name ? candidate_summary.middle_name : "" }}
                    {{ candidate_summary.last_name }}
                  </a>
                  <span href="#"><i class="flaticon2-correct text-success font-size-h5"></i></span>
                </div>
                <div class="my-lg-0 my-3" v-if="this.resultId">
                  <v-btn @click.prevent="previewReport()" x-large dark>
                    <i class="fas fa-list-alt"></i> Preview report</v-btn>
                </div>
              </div>

              <div class="d-flex flex-wrap justify-content-between mt-1">
                <div class="d-flex flex-column flex-grow-1 pr-8">
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-lg-8 ml-5 mb-lg-0 mb-2">
                     <strong>Candidate name</strong>:
                      {{ candidate_summary.full_name }}
                   </span>
                  </div>
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-lg-8 ml-5 mb-lg-0 mb-2">
                     <strong>Gender</strong>:
                      {{ candidate_summary.gender_text }}
                   </span>
                  </div>
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-lg-8 ml-5 mb-lg-0 mb-2">
                     <strong>Date of birth</strong>:
                      {{ candidate_summary.formatted_date_of_birth? candidate_summary.formatted_date_of_birth: "NA"}}
                    </span>
                  </div>
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-3 ml-5">
                     <strong>AMEB candidate number</strong>:
                      {{candidate_summary.ameb_candidate_number ? candidate_summary.ameb_candidate_number: "NA"}}
                    </span>
                  </div>
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-3 ml-5">
                     <strong>Enrolment key</strong>:
                      {{candidate_summary.exam_key ? candidate_summary.exam_key: "NA"}}
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 w-25 flex-fill float-right mt-lg-12 mb-3">
                  <div class="d-flex flex-wrap mt-4">
                    <span @click="goToExam(candidate_summary.exam_id)" class="mr-3 ml-5">
                      <strong>Exam name</strong>:
                      {{candidate_summary.exam_name ? candidate_summary.exam_name : "NA" }}
                    </span>
                  </div>
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-lg-8 ml-5 mb-lg-0 mb-2"
                      ><strong>Class identifier</strong>:
                      <span class="badge" :class="candidate_summary.is_class_exam == 0 ? 'badge-danger' : 'badge-success' ">
                        {{candidate_summary.is_class_exam == 0 ? "No" : "Yes" }}
                      </span>
                    </span>
                  </div>
                  <div class="d-flex flex-wrap mt-4">
                   <span class="mr-lg-8 ml-5 mb-lg-0 mb-2"><strong>Added by</strong>:
                      <span class="badge" :class="candidate_summary.is_class_exam == 0? 'badge-danger' : 'badge-success' ">
                        {{ candidate_summary.is_class_exam == 0 ? "No" : "Yes" }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <v-spacer></v-spacer>
      <div class="col-md-12">
        <v-row v-if="!this.resultId">
          <v-col md="12">
            <v-autocomplete
              outlined
              :error="$v.practical_exam_report.examiner_id.$error"
              :items="examiners"
              @change="getResultDataByExaminer"
              item-text="first_name"
              item-value="id"
              clearable
              v-model="practical_exam_report.examiner_id"
              dense
            >
              <template v-slot:label>
                Examiner <span class="text-danger">*</span>
              </template>
              <template v-slot:selection="data">
                {{ data.item.first_name }} {{ data.item.last_name }}
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ data.item.first_name }}
                      {{ data.item.last_name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <span
              class="text-danger"
              v-if="$v.practical_exam_report.examiner_id.$error"
              >Examiner field must be selected</span
            >
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col md="12">
            <v-text-field outlined v-model="practical_exam_report.title" dense>
              <template v-slot:label> Report file title </template>
            </v-text-field>
          </v-col>
          -->
          <v-col cols="12" class="text-right">
            <div class="inline-block icon_musical_notation mr-5 text-lg" v-copy="`✓`">✓</div>
            <div class="inline-block icon_musical_notation mr-5 text-lg" v-copy="`ˣ`">ˣ</div>
            <div class="inline-block icon_musical_notation mr-5" v-copy="`♪`">♪</div>
            <div class="inline-block icon_musical_notation mr-5" v-copy="`♫`">♫</div>
            <div class="inline-block icon_musical_notation mr-5" v-copy="`ᴑ`">ᴑ</div>
            <div class="inline-block icon_musical_notation mr-5" v-copy="`b`">b</div>
          </v-col>
          <v-col md="12">
            <v-select
                @change="selectedNewReportType()"
                :items="report_types"
                v-model="practical_exam_report.report_type"
                :error="$v.practical_exam_report.report_type.$error"
                item-text="text"
                item-value="value"
                outlined
                dense
            >
              <template v-slot:label>
                Report type <span class="text-danger">*</span>
              </template>
            </v-select>
            <span
                class="text-danger"
                v-if="$v.practical_exam_report.report_type.$error"
            >Report type field must be selected</span
            >
          </v-col>

          <v-col md="12" v-if="practical_exam_report.report_type == 'scanned'">
            <v-file-input
              outlined
              dense
              prepend-icon=""
              v-model="practical_exam_report.path"
              prepend-inner-icon="mdi-file"
              accept="application/pdf"
              label="File input"
            >
              <template v-slot:label> Report file </template>
            </v-file-input>
          </v-col>
          <v-col cols="12" v-if="practical_exam_report.report_type == 'scanned'">
            <v-btn
              v-if="resultId && practical_exam_report.file_attachment"
              class="btn btn-primary text-white"
              @click="viewAttachment(practical_exam_report.file_attachment)"
            >
              <i class="fa fa-eye"></i>  View file
            </v-btn>
          </v-col>

          <v-col md="12">
            <v-select
              v-if="mark_types == 'grade'"
              outlined
              v-model="practical_exam_report.mark_obtained"
              :items="mark_obtained"
              :error="$v.practical_exam_report.mark_obtained.$error"
              dense
            >
              <template v-slot:label> Mark obtained </template>
            </v-select>
            <v-select
              v-if="mark_types == 'title'"
              outlined
              v-model="practical_exam_report.mark_obtained"
              :items="mark_titles"
              :error="$v.practical_exam_report.mark_obtained.$error"
              dense
            >
              <template v-slot:label> Mark title </template>
            </v-select>
            <v-select
                v-if="mark_types == 'rockschool_professional_diplomas'"
                outlined
                v-model="practical_exam_report.mark_obtained"
                :items="rockschool_professional_diplomas"
                :error="$v.practical_exam_report.mark_obtained.$error"
                dense
            >
              <template v-slot:label>Rockschool professional diploma </template>
            </v-select>
            <v-text-field
              dense
              outlined
              v-if="mark_types == 'percentage' || mark_types == 'marks'"
              :label="mark_types == 'percentage' ? 'Percentage' : 'Mark'"
              v-model="practical_exam_report.mark_obtained"
              :error="$v.practical_exam_report.mark_obtained.$error"
            >
            </v-text-field>

            <span
              class="text-danger"
              v-if="$v.practical_exam_report.mark_obtained.$error"
              >Mark obtained field must be selected</span
            >
          </v-col>
          
          <v-col cols="12">
            <v-checkbox
              v-if="showNominateForPrizeField"
              label="Nominate for prize"
              v-model="practical_exam_report.nominate_for_prize"
            ></v-checkbox>
          </v-col> 
          
          <v-col
            cols="6"
            v-if="fields.length > 0"
            v-for="(field, index) in fields"
          >
            <label>{{ field.text }}</label>
            <ckeditor
                :config="getEditorConfig()"
              :value="field.text"
              v-model="practical_exam_report[field.value]"
            >
            </ckeditor>
          </v-col>

          <v-col
            cols="12"
          >
            <label>My examining notes (Won't appear on the report) </label>
            <ckeditor
              :config="getEditorConfig()"
              v-model="practical_exam_report.examiner_notes"
            >
            </ckeditor>
          </v-col>

          
          <v-col cols="12" sm="12" md="12" class="text-right">
            <v-btn x-large text @click="closeEdit()">
              Close
            </v-btn>
            <v-btn
             dark
             x-large
              @click="saveReport()"
              :loading="isLoading"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>



        <!-- attachment part -->
        <div class="row px-4 border-top">
          <v-col cols="12">
            <div class="mt-5">
              <h3>Upload attachments</h3>
              <div>
                If you’re uploading your official report, please do that above. You can use this Uploads section to add items for the internal archive, or other documents to share when the Report is issued. Everything will remain hidden from Enrollers unless you confirm you want them to be made available.
              </div>
              
              <div class="my-2">
                <v-text-field
                    class="mt-3 mb-1"
                    outlined
                    dense
                    :error="$v.candidate_attachment.title.$error"
                    v-model="candidate_attachment.title"
                >
                    <template v-slot:label>
                        Attachment title <span class="text-danger">*</span>
                    </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.candidate_attachment.title.$error">This information is required</span>
              </div>

              <div class="mt-2">
                <v-select
                    v-model="candidate_attachment.attachment_type"
                    outlined
                    @change="handleAttachmentTypeChange"
                    dense
                    :items="attachment_types"
                    item-value="value"
                    item-text="name"
                    :error="$v.candidate_attachment.attachment_type.$error"
                >
                    <template slot="label">
                        Attachment type <span class="text-danger">*</span>
                    </template>
                </v-select>
                <!-- <span class="text-danger" v-if="errors.attachment_type" >*{{errors.attachment_type[0]}}</span> -->
                <span class="text-danger" v-if="$v.candidate_attachment.attachment_type.$error" >This information is required</span>
              </div>

              <div v-if="showFile" class="mt-2">
                  <v-file-input
                      outlined
                      dense
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"  @change="checkSize($event)"
                      v-model="candidate_attachment.uploadFile"
                      :error="$v.candidate_attachment.uploadFile.$error"
                  >
                      <template slot="label">
                          Select file to upload <span class="text-danger">*</span>
                      </template>
                  </v-file-input>
                  <span class="text-danger" v-if="sizeExceed">The file you’ve tried to upload is too big. Please upload a file under 20MB instead.</span>
                  <!-- <span class="text-danger" v-if="errors.uploadFile" >*{{errors.uploadFile[0]}}</span> -->
                  <span class="text-danger" v-if="$v.candidate_attachment.uploadFile.$error" >This information is required</span>
              </div>

              <div v-if="showLink" class="mt-2">
                  <v-text-field
                      outlined
                      dense
                      v-model="candidate_attachment.url"
                      :error="$v.candidate_attachment.url.$error"
                  >
                      <template slot="label">
                          Enter URL of the attachment <span class="text-danger">*</span>
                      </template>
                  </v-text-field>
                  <!-- <span class="text-danger" v-if="errors.url" >*{{errors.url[0]}}</span> -->
                  <span class="text-danger" v-if="$v.candidate_attachment.url.$error" >This information is required</span>
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-if="candidate_attachment.uploadFile || candidate_attachment.url"
              v-model="candidate_attachment.available_to_examiner"
              label="Available to examiner"
            ></v-checkbox>
            <span class="" v-if="candidate_attachment.uploadFile || candidate_attachment.url">
              (Note : This will make attachment available to all the examiners)
            </span>
          </v-col> 

          <v-col cols="12">
            <div class="my-2 text-right">
              <v-btn
                class="btn btn-primary text-white ml-4"
                @click="uploadAttachment"
                :disabled="sizeExceed"
                :loading="isUploadingAttachment"
              >
                <i class="fa fa-upload"></i> Upload
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="text-right">
              <v-btn
                :disabled="attachment_ids.length == 0"
                color="orange"
                @click="downloadAsZip"
                class="text-white"
                :loading="isDownloading"
              >
                <i class="fa fa-download"></i> Download selected as zip
              </v-btn>

              <v-btn
                color="blue"
                @click="downloadAllAsZip"
                class="text-white ml-3"
                :disabled="candidate_attachments.length == 0"
                :loading="isAllDownloading"
              >
                <i class="fa fa-download"></i> Download all as zip
              </v-btn>
            </div>
            <v-skeleton-loader
              type="table-thead"
              v-if="isLoading"
            >
            </v-skeleton-loader>

            <v-skeleton-loader
              v-if="isLoading"
              type="table-row-divider@5"
            >
            </v-skeleton-loader>

            <table v-if="! isLoading" class="table table-vertical-center">
              <thead>
                <tr class="text-left">
                  <th class="px-2">
                    <v-checkbox
                      @click="checkAllAttachment"
                      v-model="checkAllAttachmentFlag"
                    ></v-checkbox>
                  </th>
                  <th class="px-2">Title</th>
                  <th class="px-2">Enroller visibility</th>
                  <th class="px-2">Upload date</th>
                  <th class="px-2">Examiner visibility </th>
                  <th class="px-2">Attachment</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                    v-if="candidate_attachments.length > 0" 
                    v-for="(item,i) in candidate_attachments" 
                    :key="i"
                >
                  <td class="px-2">
                    <v-checkbox
                      v-model="attachment_ids"
                      :value="item.id"
                    ></v-checkbox>
                  </td>
                  <td>
                    <div class="px-2">{{item.title ? item.title : 'NA'}}</div>
                  </td>
                  <td>
                    <div class="px-2">
                      <span 
                        class="badge pl-3 pt-2" 
                        :class="item.available_to_account_holder ? 'badge-success' : 'badge-danger'"
                      >
                        {{item.available_to_account_holder ? 'Yes' : 'No' }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="px-2">{{item.upload_date ? item.upload_date : '-'}}</div>
                  </td>
                  <td>
                    <div class="px-2">
                      <span 
                        class="badge pl-3 pt-2" 
                        :class="item.available_to_examiner ? 'badge-success' : 'badge-danger'"
                      >
                        {{item.available_to_examiner ? 'Yes' : 'No' }}
                      </span>
                    </div>
                  </td>
                  <td class="px-2">
                    <template>
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text v-if="item.file_path" tag="div" class="navi-item">
                            <a  
                              :href="item.file_path.original" 
                              class="navi-link"
                              target="_blank"
                            >
                              <span class="navi-icon">
                                  <i class="fas fa-eye"></i>
                              </span>
                              <span class="navi-text">View</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text v-if="item.attachment_type == 'url' && item.url" tag="div" class="navi-item">
                            <a  
                              :href="item.url" 
                              class="navi-link"
                              target="_blank"
                            >
                              <span class="navi-icon">
                                  <i class="fas fa-link"></i>
                              </span>
                              <span class="navi-text">View</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" v-if="item.file_path || item.url"  class="navi-item">
                            <a
                              class="navi-link" 
                              @click="changeAccountHolderVisibility(item.id)"
                            >
                              <span class="navi-icon">
                                <i :class="item.available_to_account_holder ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                              </span>
                              <span class="navi-text">
                                {{ item.available_to_account_holder ? 'Hide from enroler' : 'Make visible to enroler' }}
                              </span>
                            </a>
                          </b-dropdown-text>
                          
                          <b-dropdown-text v-if="item.file_path || item.url" tag="div" class="navi-item">
                            <a
                                class="navi-link"
                                @click="deleteAttachmentReport(item.id, item.type)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="candidate_attachments.length == 0">
                    <td class="text-center" colspan="5">No attachments available</td>
                </tr>
              </tbody>
            </table>
            <b-pagination
                v-if="candidate_attachments.length > 0"
                class="pull-right mt-7"
                @input="getCandidateAttachmentsByType"
                :loading="isLoading"
                v-model="page_attachments"
                :total-rows="total_attachments"
                :per-page="per_page_attachments"
                first-number
                last-number
            ></b-pagination>
          </v-col> 
        </div>

      </div>
    </v-app>
  </div>
</template>
<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import CandidateAttachmentService from "@/services/candidate/attachment/CandidateAttachmentService";
import ExaminerService from "@/services/user/examiner/ExaminerService";
import { required, email, numeric, requiredIf } from "vuelidate/lib/validators";
import SettingService from "@/services/setting/SettingService";

const setting = new SettingService();
const examiner = new ExaminerService();
const candidateAttachment = new CandidateAttachmentService();
const candidate = new CandidateService();
const candidatePracticalExamResult = new CandidatePracticalExamResultService();
export default {
  validations: {
    practical_exam_report: {
      examiner_id: { required },
      report_type: { required },
      mark_obtained: { required },
    },
    candidate_attachment:{
      title: {required},
      attachment_type: {required},
      uploadFile: {
          required: requiredIf(function () {
              return this.candidate_attachment.url == '';
          })
      },
      url: {
          required: requiredIf(function () {
              return this.candidate_attachment.uploadFile == '';
          })
      },
    },
  },

  data() {
    return {
      // candidate_attachment:{
      //   uploadFile: null,
      //   candidate_id: null,
      //   attachment_type: 'file',
      //   type: 'report',
      //   available_to_examiner: 0,
      //   available_to_account_holder: null,
      //   uploaded_by_type: 'admin'
      // },
      candidate_attachment:{
        title: '',
        candidate_id: null,
        type: 'report',
        attachment_type: '',
        uploadFile: null,
        description: '',
        url: '',
        available_to_examiner: 0,
        available_to_account_holder: null,
        uploaded_by_type: 'admin',
      },
      attachment_types:[
        {
            name: 'File',
            value: 'file'
        },
        {
            name: 'URL',
            value: 'url'
        }
      ],
      isUploadingAttachment: false,
      candidate_summary: {},
      candidate_attachments: [],
      loading: false,
      isDownloading: false,
      isLoading: false,
      practical_exam_report: {
        title: "",
        mark_type: "",
        mark_obtained: "",
        candidate_id: "",
        examiner_id: "",
        report_type: "digital",
        path: null,
        technical_work: "",
        piece_one: "",
        piece_two: "",
        piece_three: "",
        piece_four: "",
        own_choice: "",
        aural_test: "",
        sight_reading: "",
        general_knowledge: "",
        general_remarks: "",
        examiner_notes: "",
        nominate_for_prize: null,
      },
      report_types: [
        { value: "digital", text: "SCORE" },
        { value: "scanned", text: "Upload" },
      ],
      mark_titles: [],
      rockschool_professional_diplomas:[],
      mark_obtained: [],
      mark_options:[],
      fields: [],
      examiners: [],
      editorConfig: {
       /* scayt_autoStartup: true,
        allowedContent: true,
        pasteFromWordRemoveFontStyles: true,
        pasteFromWordRemoveStyles: true,
        pasteFromWordNumberedHeadingToList: true,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "FontSize",
            "NumberedList",
            "BulletedList",
            "SpellChecker",
            "Scayt",
            "PasteFromWord",
          ],
        ],
        extraPlugins: ['font'],*/
      },
      mark_types: "",
      total_attachments: null,
      per_page_attachments: null,
      current_page_attachments: null,
      page_attachments: null,
      meta_attachments:{},
      checkAllAttachmentFlag: false,
      attachment_ids: [],
      isAllDownloading: false,
      showFile: false,
      showLink: false,
      sizeExceed: false,
      errors: []
    };
  },
  methods: {
    getEditorConfig(){
      return {
        scayt_autoStartup:true,
        scayt_sLang: 'en_GB',
        allowedContent:true,
        pasteFromWordRemoveFontStyles:true,
        pasteFromWordRemoveStyles: true,
        pasteFromWordNumberedHeadingToList: true,
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'RemoveFormat', 'Strike','FontSize',"NumberedList" ,'BulletedList','Subscript', 'Superscript', 'SpellChecker', 'Scayt','PasteFromWord',]
        ],
        extraPlugins: ['font'],
        versionCheck: false
      }
    },
    downloadAllAsZip(){
      let data = {
        candidate_id: this.candidate_summary.id,
        exam_key: this.candidate_summary.exam_key,
      };
      candidateAttachment
      .downloadAsZip(data, 'all');
    },

    downloadAsZip(){
      let data = {
        candidate_attachment_ids: this.attachment_ids,
        exam_key: this.candidate_summary.exam_key,
      };
      candidateAttachment
      .downloadAsZip(data, 'selected');

      this.attachment_ids = [];
    },

    checkSize(file) {
        if(file){
            let size = file.size / 1024 / 1024;
            let mbSize = size.toFixed(2);
            if (mbSize > 500) {
                this.sizeExceed = true;
            } else {
                this.sizeExceed = false;
            }
        }
    },
    
    checkAllAttachment(){
      if(this.checkAllAttachmentFlag){
          this.candidate_attachments.forEach(item => {
              this.attachment_ids.push(item.id);
          });
      }
      else{
          this.attachment_ids = [];
      }
    },
    closeEdit() {
      this.$tabs.close();
    },
    getResultDataByExaminer() {
        this.getExaminerInfo(this.candidate_summary.examiner_info);
    },
    getCandidateSummary() {
      this.loading = true;
      candidate
        .getCandidateSummary(this.examKey)
        .then((response) => {
          this.candidate_summary = response.data.candidate;
          this.changeReportType();
          this.getExaminerInfo(this.candidate_summary.examiner_info);
          this.checkMarkType(this.candidate_summary.id);
          this.getCandidateAttachmentsByType();
          this.getMarkedTitleOption();
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    convertToFormData() {
      this.practical_exam_report.mark_type = this.mark_types;
      this.practical_exam_report.candidate_id = this.candidate_summary.id;
      
      if(this.practical_exam_report.report_type == 'scanned'){
          this.practical_exam_report.title = 'Uploaded report - '+this.candidate_summary.full_name;
      }
      if(this.practical_exam_report.report_type == 'digital'){
          this.practical_exam_report.title = 'Digital report - '+this.candidate_summary.full_name;
      }

      let formData = new FormData();
      for (let key in this.practical_exam_report) {
        if (
          key === "path" &&
          this.practical_exam_report[key] != null &&
          this.practical_exam_report[key] != undefined
        ) {
          formData.append("path", this.practical_exam_report[key]);
        } else {
          if (this.practical_exam_report[key]) {
            formData.append(key, this.practical_exam_report[key]);
          }
        }
      }
      return formData;
    },
    saveReport() {
      this.$v.practical_exam_report.$touch();
      
      if (this.$v.practical_exam_report.$error) {
        setTimeout(() => {
          this.$v.practical_exam_report.$reset();
        }, 3000);
      } else {
        let formData = this.convertToFormData();
        this.isLoading = true;
        candidatePracticalExamResult
          .create(formData)
          .then((response) => {
            this.getCandidateSummary();
            this.changeReportType();
            this.$snotify.success("Report added");
            this.closeEdit();
          })
          .catch((error) => {
            this.errors = error.errors;
            if(this.errors.path){
              this.$snotify.error('Please ensure that you upload a file with the .pdf extension only.');
            }else{
              this.$snotify.error('Something went wrong.');
            }

          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
      selectedNewReportType(){
        this.changeReportType();
        /*  
        // if(this.exam_reports){
          this.$confirm({
              message: `Confirm change report type? `,
              button: {
                  no: "No",
                  yes: "Yes",
              },
              callback: (confirm) => {
                  if (confirm) {
                      this.changeReportType();
                  }else{
                      if(this.practical_exam_report.report_type == 'digital'){
                          this.practical_exam_report.report_type = 'scanned';
                          return true;
                      }
                      if(this.practical_exam_report.report_type == 'scanned'){
                          this.practical_exam_report.report_type = 'digital';
                          return true;
                      }
                      return false;
                  }
              }
          });
          */
      },
    changeReportType() {
      if (this.practical_exam_report.report_type == "digital") {
        candidatePracticalExamResult
          .checkDigitalReport(this.candidate_summary.id)
          .then((response) => {
            let fields = response.data.practical_result_report_options;
            for (let key in fields) {
              let data = { value: key, text: fields[key] };
              this.fields.push(data);
            }
            this.practical_exam_report.update = true;
            this.checkMarkType(this.candidate_summary.id);
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        this.fields = [];
      }
    },
    checkMarkType(candidateId) {
      candidatePracticalExamResult
        .checkMarkType(candidateId)
        .then((response) => {
          this.mark_types = response.data.mark_type;


        });
    },
    practicalExamReportDetail() {
      if (this.resultId) {
        candidatePracticalExamResult
          .show(this.resultId)
          .then((response) => {
            if (response.data.candidatePracticalExamResult) {
              this.practical_exam_report =
                response.data.candidatePracticalExamResult;
            }
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        this.practical_exam_report.title = "";
        this.practical_exam_report.mark_type = "";
        this.practical_exam_report.mark_obtained = "";
        this.practical_exam_report.candidate_id = "";
        this.practical_exam_report.report_type = "digital";
        this.practical_exam_report.path = null;
        this.practical_exam_report.technical_work = "";
        this.practical_exam_report.piece_one = "";
        this.practical_exam_report.piece_two = "";
        this.practical_exam_report.piece_three = "";
        this.practical_exam_report.piece_four = "";
        this.practical_exam_report.own_choice = "";
        this.practical_exam_report.aural_test = "";
        this.practical_exam_report.sight_reading = "";
        this.practical_exam_report.general_knowledge = "";
        this.practical_exam_report.general_remarks = "";
        this.practical_exam_report.mark_type_text = "";
        this.practical_exam_report.report_type_text = "";
      }
    },
    previewReport() {
      if (this.practical_exam_report.report_type == "scanned") {
        if (this.practical_exam_report.file_attachment) {
          window.open(this.practical_exam_report.file_attachment, "_blank");
        } else {
          this.$snotify.error("Report Not Found");
        }
      } else {
        candidatePracticalExamResult
          .previewExamReport(this.practical_exam_report.id)
          .then((response) => {})
          .catch((error) => {
            this.isLoading = false;
          });
      }
    },
    getExaminerInfo(examinerInfo) {
      let data = { info: examinerInfo };
      examiner
        .getExaminerById(data)
        .then((response) => {
          this.examiners = response.data.examiners;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    getMarkedObtainedOption() {
      setting.getMarkedObtainedOption().then((response) => {
        for (let key in response.data.marked_obtained_options) {
          this.mark_obtained.push({
            value: key,
            text: response.data.marked_obtained_options[key],
          });
        }
      });
    },
   getMarkedTitleOption() {
      let data = {
        candidate_id: this.candidate_summary.id
      };

      setting.getMarkedTitleOption(data).then((response) => {
        for (let key in response.data.mark_title_option) {
          this.mark_titles.push({
            value: key,
            text: response.data.mark_title_option[key],
          });
        }
      });
    },
    getRockschoolDiplomas(){
      setting.getRockSchoolDiplomaOption().then((response) => {

        for (let key in response.data.rockschool_diploma_value) {

          this.rockschool_professional_diplomas.push({
            value: key,
            text: response.data.rockschool_diploma_value[key],
          });
        }
      });
    },

    goToExam(exam_id) {
      this.$router.push({
        name: "product-edit",
        params: { productId: exam_id },
      });
    },
    getCandidateAttachmentsByType(){
      this.isLoading = true;
      let param = [
        'result', 'report', 'printed_report'
      ];
      candidateAttachment
      .getByMultipleType(param, this.page_attachments, this.candidate_summary.id)
      .then(response => {
          this.meta_attachments = response.data;
          this.page_attachments = response.data.current_page;
          
          this.total_attachments = response.data.total;
          this.per_page_attachments = response.data.per_page;
          this.current_page_attachments = response.data.current_page;
          this.candidate_attachments=response.data.data;
          this.isLoading = false;
      })
      .catch((err) => {
          this.isLoading = false;
      });
    },
    // convertAttachmentsToFormData(){
    //     this.candidate_attachment.candidate_id = this.candidate_summary.id;
    //     let formData = new FormData();
    //     for (let key in this.candidate_attachment) {
    //         if (key === "uploadFile" && this.candidate_attachment[key] != null && this.candidate_attachment[key] != undefined) {
    //             for(let image in this.candidate_attachment[key]){
    //                 formData.append('uploadFile[]', this.candidate_attachment[key][image]);
    //             }
    //         }
    //         else {
    //             if(this.candidate_attachment[key]){
    //                 formData.append(key,this.candidate_attachment[key]);
    //             }
    //         }
    //     }
    //     return formData;
    // },
    convertAttachmentsToFormData() {
      this.candidate_attachment.candidate_id = this.candidate_summary.id;
      if(this.candidate_attachment.available_to_examiner){
          this.candidate_attachment.available_to_examiner = 1;
      }else{
          this.candidate_attachment.available_to_examiner = 0;
      }
      let formData = new FormData();
      for (let key in this.candidate_attachment) {
          if (key === "uploadFile" && this.candidate_attachment[key] != null && this.candidate_attachment[key] != undefined) {
              formData.append('uploadFile', this.candidate_attachment[key]);
          }
          else {
              if(this.candidate_attachment[key]){
                  formData.append(key,this.candidate_attachment[key]);
              }
          }
      }
      return formData;
  },
    // uploadAttachments(){
    //   this.isUploadingAttachment = true;
    //   let formData = this.convertAttachmentsToFormData();
    //   candidateAttachment
    //   .createMultipleReports(formData)
    //   .then((response) => {
    //       this.isUploadingAttachment = false;
    //       this.getCandidateSummary();
    //       this.$snotify.success('Attachments uploaded successfully.');
    //       this.resetForm();
    //   })
    //   .catch((err) => {
    //       this.isUploadingAttachment = false;
    //       this.$snotify.error('Attachments could not be uploaded.');
    //   })
    // },

    uploadAttachment() {
      this.$v.candidate_attachment.$touch()
      if (this.$v.candidate_attachment.$error) {
          setTimeout(() => {
              this.$v.candidate_attachment.$reset()
          }, 60000);
      }
      else {
          this.isUploadingAttachment = true;
          let formData = this.convertAttachmentsToFormData();

          candidateAttachment
          .create(formData)
          .then((response) => {
            this.isUploadingAttachment = false;
            this.getCandidateSummary();
            this.$snotify.success('Attachment uploaded successfully.');
            this.resetAttachmentForm();
          })
          .catch((err) => {
            this.isUploadingAttachment = false;
            this.$snotify.error('Attachments could not be uploaded.');
          })
          .finally(() => {
              this.isUploadingAttachment = false;
          });
      }
    },

    resetAttachmentForm(){
      this.candidate_attachment = {
        title: '',
        candidate_id: null,
        type: 'report',
        attachment_type: '',
        uploadFile: null,
        description: '',
        url: '',
        available_to_examiner: 0,
        available_to_account_holder: null,
        uploaded_by_type: 'admin',
      };
      this.$v.$reset();
    },


    deleteAttachmentReport(attachmentId, type){
      if(type == 'printed_report' || type == 'result'){
        this.$snotify.error('The live result cannot be deleted');
        return false;
      }

      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidateAttachment
            .delete(attachmentId)
            .then((response) => {
              this.getCandidateAttachmentsByType();
              this.$snotify.success('Attachment deleted');
            })
            .catch((err) => {
              this.$snotify.error("Oops we could not delete the attachment.");
            });
          }
        }
      })
    },
    resetForm(){
      this.candidate_attachment={
        uploadFile: null,
        candidate_id: null,
        attachment_type: 'file',
        type: 'report',
        available_to_examiner: 0,
        available_to_account_holder: null,
        uploaded_by_type: 'admin'
      };
    },
    viewAttachment(fileAttachment){
      window.open(fileAttachment, "_blank");
    },
    changeAccountHolderVisibility(candidateAttachmentId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            candidateAttachment
            .changeAccountHolderVisibility(candidateAttachmentId)
            .then((response) => {
              this.getCandidateAttachmentsByType();
              this.$snotify.success('Enroller status changed successfully.');
            })
            .catch((err) => {
              this.$snotify.error("Oops we could not change the status.");
            });
          }
        }
      })
    },
    handleAttachmentTypeChange(){
      if(this.candidate_attachment.attachment_type == 'file'){
          this.showFile = true;
          this.showLink = false;
          this.candidate_attachment.url = '';
      }
      if(this.candidate_attachment.attachment_type == 'url'){
          this.showLink = true;
          this.showFile = false;
          this.candidate_attachment.uploadFile = '';
      }
    },
  },

  computed: {
    examKey() {
      return this.$route.params.examKey;
    },
    resultId() {
      return this.$route.params.resultId;
    },
    routeTab(){
      if(this.candidate_summary){
        return {
          title: `${this.candidate_summary.full_name} - Create Practical Exam Result Report`
        }
      }
    },
    showNominateForPrizeField(){
      if(this.currentUser.access_type == 'score'){
        if(this.currentUser.score.enable_nominate_for_prize_on_report){
          if(this.candidate_summary.score_prefix == 'TAS'){
              if(this.mark_types == 'grade' && (this.practical_exam_report.mark_obtained == 'A+' || this.practical_exam_report.mark_obtained == 'A')){
                  return true;
              }
          }
          else{
              if(this.mark_types == 'grade' && this.practical_exam_report.mark_obtained == 'A+'){
                return true;
              }
          }
        }  
      }
      if(this.currentUser.access_type == 'federal'){
        if(this.candidate_summary.score_prefix == 'TAS'){
          if(this.mark_types == 'grade' && (this.practical_exam_report.mark_obtained == 'A+' || this.practical_exam_report.mark_obtained == 'A')){
              return true;
          }
        }
        else{
            if(this.mark_types == 'grade' && this.practical_exam_report.mark_obtained == 'A+'){
              return true;
            }
        }
      }
      return false;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getCandidateSummary();
    this.getExaminerInfo();
    this.practicalExamReportDetail();

    this.getMarkedObtainedOption();
    // this.getMarkedTitleOption();
    this.getRockschoolDiplomas();
  },
};
</script>